import { Box, Divider, Heading, Link, Tag, Text } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React from "react"
import rehypeReact from "rehype-react"
import { TableOfContent } from "../../components/blog/tableOfContent/TableOfContent"
import { MainContainer } from "../../components/containers/mainContainer/MainContainer"
import { FixedSidebar } from "../../components/containers/sideBars/fixedSidebar/FixedSidebar"
import { SidebarLeft } from "../../components/containers/sideBars/SideBarLeft"
import { SidebarRight } from "../../components/containers/sideBars/SidebarRight"
import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { Layout } from "../../components/layout/Layout"
import { Seo } from "../../components/seo/Seo"

type Props = {
  data: any
}

// @ts-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const Template: React.FC<Props> = ({ data }) => {
  const { sidebar, title, metaTitle, metaDesc } = data.page.frontmatter
  return (
    <Layout blog={true}>
      <Seo title={metaTitle} desc={metaDesc} />
      <Heading as="h1" textAlign="center" marginY="2rem">
        {title}
      </Heading>

      <MainContainer>
        {sidebar && (
          <SidebarLeft>
            <FixedSidebar>
              <TableOfContent headings={data.page.headings} />
            </FixedSidebar>
          </SidebarLeft>
        )}
        <Box flex="7">
          <Divider />
          <Box display="flex" justifyContent="center">
            <Link href="https://www.cvpohjat.fi/" target="_blank">
              <Box>
                <Tag marginRight="1rem" marginTop="0.3rem">
                  Mainos
                </Tag>

                <Text>
                  Valitse CV malli ja luo toimiva ansioluettelo minuuteissa{" "}
                  {">>>"}{" "}
                  <Text as="span" fontWeight="500">
                    cvpohjat.fi
                  </Text>
                </Text>
              </Box>
            </Link>
          </Box>
          <Divider />

          <TextContainer>{renderAst(data.page.htmlAst)}</TextContainer>
        </Box>
        {sidebar && (
          <SidebarRight>
            <FixedSidebar>
              <TableOfContent headings={data.page.headings} />
            </FixedSidebar>
          </SidebarRight>
        )}
      </MainContainer>
    </Layout>
  )
}

export const query = graphql`
  query($filter: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $filter } }) {
      htmlAst
      html
      timeToRead
      frontmatter {
        title
        metaTitle
        metaDesc
        sidebar
      }
      headings {
        value
        depth
      }
    }
  }
`

export default Template
